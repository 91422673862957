.keyword-table {
  width: 100%;
  background: #182232;
  padding: 40px;
  border-collapse: collapse;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  color: #fff;
}

.keyword-table thead{
  padding: 20px;
  border-bottom: 1px solid #27354d;
}

.keyword-table tbody tr {
  border-bottom: 1px solid #27354d;
}

.keyword-table th,
.keyword-table td {
  text-align: center;
  padding: 20px 20px;
}

.keyword-table .first-column {
  text-align: left;
}

.keyword-table td button {
  padding: 0px;
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 0px ;
  color: #9b9b9b;
}

.keyword-table td button:hover {
  color: #000;
}

.positive-diff{
  color: #3EA173;
  background: #E7F4EE;
  text-align: center;
  font-weight: bold;
  padding: 5px 10px 5px 10px;
  border-radius: 20px;
}

.negative-diff {
  color: #A13E3E;
  background: #F4E7E7;
  text-align: center;
  font-weight: bold;
  padding: 5px 10px 5px 10px;
  border-radius: 20px;
}