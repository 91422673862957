/* src/App.css */

body {
  font-family: 'Arial', sans-serif;
  background-color: #fff;
  margin: 0;
  padding: 0;
  background-color: #121c2b;
}

.App {
  text-align: center;
  padding: 50px 20px;
}

h1 {
  color: #333;
  font-size: 2em;
  margin-bottom: 20px;
}

.cancelButton{
  border: 1px solid #000;
  background: #fff;
  color: #000;
}

button {
  padding: 1rem;
  margin: 10px;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  color: #000;
  font-family: "raleway-bold"; 
}

.cta-button{
  background: #12B7EF;
}

@font-face {
  font-family: 'raleway-black';
  src: url(./assets/fonts/Raleway-Black.ttf);
}

@font-face {
  font-family: 'raleway-bold';
  src: url(./assets/fonts/Raleway-Bold.ttf);
}