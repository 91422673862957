.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}


.popup-content {
    background: #182232;
    border-radius: 8px;
    padding: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 500px;
    max-width: 90%;
    color:#fff
}

.popup-content form {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Espacio entre las filas de los inputs */
    width: 100%; /* Ajusta el ancho del formulario */
}

label {
    display: flex;
    flex-direction: column;
}

input {
    padding: 10px;
    margin-top: 5px; /* Espacio entre la label y el input */
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%; /* Ajusta el ancho del input */
    box-sizing: border-box; /* Asegura que el padding esté incluido en el ancho total */
}

.addFile{
    margin-top: 20px;
    color: #fff;
}

.file-drop-area {
    border: 1px dashed #fff;
    padding: 20px;
    text-align: center;
    margin-top: 10px;
    cursor: pointer;
    position: relative;
    
    transition: background-color 0.3s ease;
}

.file-drop-area.active {
    background-color: #fff;
}

.file-upload-label {
    display: block;
    color: #000;
    font-size: 14px;
    cursor: pointer;
    color: #fff;
}

.buttons-container {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    margin-top: 20px;
}