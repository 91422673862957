.select {
  color: #fff;
  text-transform: uppercase;
  border: 0;
  background-color: transparent;
  margin: 20px 0;
}

.select option{
  color: #000;
}