.project-info .content {
  text-align: center;
  padding: 4rem 0;
}

.project-info {
  margin-bottom: 2rem;
}

.project-info button:hover {
  background-color: #fff;
  color: #000;
}

.subheader-title {
  font-size: 3em;
  color: #12b7fc;
  font-family: 'raleway-black';
  margin-bottom: 1rem !important;
}

.subheader-subtitle{
  font-family: 'raleway-bold';
  margin-bottom: 1rem !important;
}

.projects-item {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }