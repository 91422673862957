.project-detail h1 {
    margin-bottom: 20px;
}

.project-info{
  background: #121c2b;
  color: #fff;
}

.content-project{
  padding: 0px 20px;
  max-width: 1290px;
  margin: 0 auto;
  box-sizing: border-box;
}

.content {
  padding: 20px;
  max-width: 1290px;
  margin: 0 auto;
  box-sizing: border-box;
}

.keywords-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.keywords-table th, .keywords-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.keywords-table th {
    background-color: #f4f4f4;
    color: #333;
}

.keywords-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.keywords-table tr:hover {
    background-color: #ddd;
}


.keywords-table th {
    cursor: pointer;
    background-color: #f4f4f4;
    padding: 10px;
    text-align: left;
}

.keywords-table th:hover {
    background-color: #ddd;
}

.keywords-table th {
    position: relative;
}

.keywords-table th::after {
    content: ' ';
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
}


.project-details {
  padding: 20px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  width: 80%;
}

.project-details h1 {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: bold;
}

.project-details p {
  font-size: 16px;
  margin-bottom: 20px;
  color: #666;
}

.stats-and-keywords {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 20px;
}

.keyword-stats {
  flex: 2;
  margin-right: 10px;
  min-height: 200px;
}

.keyword-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 12px;
}

.keyword-table-detail{
  margin-top: 20px;
}

.last-connection{
  color: #fff;
}