.actions-bar {
  display: flex;
  align-items: center;
  padding: 20px 20px;
  gap: 20px; 
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #182232;
}

.batch-actions,
.filter-tags,
.keyword-search {
  padding: 8px 0;
  font-size: 13px;
  border: none;
  background-color: #2f3d55;;
  color: #929292;
  border-radius: 3px !important;
}

.batch-actions {
  width: 200px;
}

.filter-tags {
  width: 150px;
}  

.keyword-search {
  font-size: 14px;
  padding-left: 10px;
  width: 100%;
  margin-top: 0px;
  outline: none;
}

