.stat {
  padding: 20px;

  background-color: #182232;
  border-radius: 8px;
}

p {
  margin: 0 !important;
}

.stat-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.stat-icon {
  margin-right: 10px;
}

.stat-title{
  color: #fff;
}

.stat-info{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.stat-value {
  font-size: 2em;
  font-weight: bold;
  color: #12B7FC;
}

.stat-trend {
  color: #3EA173;
  background: #E7F4EE;
  text-align: center;
  font-weight: bold;
  padding: 5px 10px 5px 10px;
  border-radius: 20px;
}

.stats-icon{
  padding: 10px;
  border-radius: 100%;
  width: 20px;
  height: 20px;
}

.rank{
  background-color: #000000;
  color: #ffffff;
}

.top1{
  background-color: #FFD78D;
  color: #FBAE1F;
}

.top2{
  background-color: #e2e2e2;
  color: #BEBEBE;
}

.top4{
  background-color: #f5b472;
  color: #CD7F32;
}

.top11{
  background-color: #ffb9a5;
  color: #CB6D51;
}

.top31{
  background-color: #ebebeb;
  color: #ffffff;
}