.data{
  padding: 20px 20px 0px 20px;
  border-radius: 8px;
  background-color: #182232;
}

.data:hover{
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}

.link-to-project{
  text-decoration: none;
  color: inherit;
}

.title{
  font-size: 1.25em !important;
  text-transform: uppercase;
  color: #fff;
}

.subtitle{
  color: #fff;
  font-weight: bold;
}

.info{
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  color: #12B7FC
}

.value {
  font-size: 2em;
  font-weight: bold;
}

.trend {
  color: #3EA173;
  background: #E7F4EE;
  text-align: center;
  font-weight: bold;
  padding: 5px 10px 5px 10px;
  border-radius: 20px;
}

.actions {
  gap: 10px;
  display: inline-flex;
  float: right;
}


.actions button{
  padding: 0;
  font-size: 1.05em;
  background-color: transparent;
  color: #9c9c9c;
}