/* src/ui/components/Login.css */
.login {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #121c2b;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.login-box {
  background-color: #182232;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.login-box h1 {
  color: white;
  margin-bottom: 20px;
}

.google-login-button {
  background-color: transparent;
  border: 2px solid white;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
  transition: background-color 0.3s, color 0.3s;
}

.google-login-button img {
    margin-right: 10px;
  }
  

.google-login-button:hover {
  background-color: white;
  color: #182232;
}

.google-login-button:hover img {
    filter: invert(1);
  }