.project-header {
  background-color: black;
  color: white;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.menu ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  align-items: center;
}

.menu ul li {
  margin: 0 15px;
  display: flex;
  align-items: center;
}

.menu ul li a {
  color: white;
  text-decoration: none;
}

.menu ul li a:hover {
  text-decoration: underline;
}

.logout-button {
  background: none;
  border: none;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
  font-size: 1rem;
  padding: 0;
}

.logout-button:hover {
  color: #0056b3;
}
