.keyword-block {
  width: 300px;
  background: #182232;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
}

.keyword-block h3 {
  width: 100%;
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
}

.circle-container {
  position: relative;
  margin-bottom: 20px;
  width: 200px;
}

.keyword-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 25px);
  font-weight: bold;
  color: #12B7FC;
}

.keyword-buttons {
  width: 100%;
}

.add-button,
.import-button {
  padding: 10px 16px;
  width: 100%;
  margin-left: 0px;
}

.add-button {
  background-color: #12B7EF;
}

.import-button {
  background-color: #e0e0e0;
  color: #000;
}
